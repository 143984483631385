import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    condition: {
      companyName: undefined,
      supplierName: undefined,
      creatorName: undefined,
    },
  },
  getters: {
    getCondition(state) {
      return state.condition
    }
  },
  mutations: {
    updateCondition(state, condition) {
      state.condition = condition
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaserefund/search', { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaserefund/view', { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaserefund/edit', { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaserefund/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaserefund/state', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approve(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaserefund/approve', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
