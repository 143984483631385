<template>
  <b-card
      class="purchaserefund-edit-wrapper"
  >
    <!-- form -->
    <ValidationObserver ref="observer">
      <b-form id="purchaserefundForm" class="edit-form mt-2">
        <b-row>
          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="退款订单编号"
                label-for="refund_no"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="refund_no"
                  size="sm"
                  readonly
                  v-model="purchaseRefund.refund_no"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="采购订单编号"
                label-for="order_no"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="order_no"
                  size="sm"
                  readonly
                  v-model="purchaseRefund.order_no"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="名称"
                label-for="name"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="name"
                  size="sm"
                  readonly
                  v-model="purchaseRefund.name"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="退款数量"
                label-for="qty"
                label-size="sm"
                class="mb-1 required"
            >
                <b-form-input
                    id="qty"
                    size="sm"
                    v-model="purchaseRefund.qty"
                    @change="checkQty(purchaseRefund)"
                />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="含税单价"
                label-for="product_cost_tax"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="product_cost_tax"
                  size="sm"
                  readonly
                  v-model="purchaseRefund.product_cost_tax"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="总退款金额"
                label-for="subtotal"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="subtotal"
                  size="sm"
                  readonly
                  v-model="purchaseRefund.subtotal"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <xy-date-picker label-cols="2" label="退款日期" :val.sync="purchaseRefund.refund_time"
                            rules="required" format="date"
                            id="refund_time"> </xy-date-picker>
          </b-col>
          <b-col md="12">
            <b-form-group
                    label-cols="2"
                    label-cols-lg="2"
                    label="支付对象"
                    label-for="payObject"
                    label-size="sm"
                    class="mb-1 required"
            >
              <b-form-input
                      id="payObject"
                      size="sm"
                      v-model="purchaseRefund.pay_object"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="备注"
                label-for="remark"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="remark"
                  v-model="purchaseRefund.remark"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="附件"
                label-for="attachments"
                label-size="sm"
                class="mb-1 required"
            >
              <attachment-upload v-if="purchaseRefund.loaded" theme="files"
                                 :readonly="readonly"
                                 attachment_id="attachments"
                                 :id="purchaseRefund.attachments"
                                 object_type="purchase_refund"
                                 :object_id="purchaseRefund.refund_id"
                                 placeholder="最好是银行回执单"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>


          <b-col
              cols="12"
              class="mt-50"
              v-if="!readonly"
          >
            <b-button
                variant="primary"
                class="mr-1"
                @click="save(1)"
            >
              保存
            </b-button>
            <b-button
                variant="primary"
                class="mr-1"
                @click="save(2)"
            >
              保存并提交
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
    <!--/ form -->
  </b-card>
</template>

<script>
import { onMounted, onUnmounted, reactive, ref, toRefs } from '@vue/composition-api'
import store from '@/store'
import purchaserefundStore from './purchaserefundStore'
import { useToast } from 'vue-toastification/composition'
import { getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import XyDatePicker from '@/views/components/xy/XyDatePicker'

export default {
  components: { XyDatePicker, AttachmentUpload },
  props: {
    orderItem:Object,
    refundId: {
      type: Number,
      default: 0
    },
    readonly:{
      type:Boolean,
      default:false
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const {
      orderItem,
      refundId
    } = toRefs(props)
    // Register module
    if (!store.hasModule('purchaserefund')) store.registerModule('purchaserefund', purchaserefundStore)

    const state = reactive({
      purchaseRefund: {
        refund_time:undefined
      },

      //refs
      observer: undefined,
    })

    if (orderItem.value) {

      state.purchaseRefund = orderItem
      //重置一些字段
      state.purchaseRefund.qty = 0
      state.purchaseRefund.subtotal = 0
      state.purchaseRefund.order_item_id = state.purchaseRefund.id
      state.purchaseRefund.new = undefined
      state.purchaseRefund.id = undefined
      state.purchaseRefund.pay_object = undefined
    }

    const validate = async () => {
      const success = await state.observer.validate()
      if (success) {
        if (state.purchaseRefund.attachments === undefined) {
          toast.error('请添加附件')
          return false
        }
        if (state.purchaseRefund.pay_object === undefined) {
          toast.error('请填写支付对象')
          return false
        }
        return true
      } else {
        toast.error('请正确填写')
        return false
      }
    }
    const methods = {
      edit() {
        store.dispatch('purchaserefund/edit', { id: refundId.value })
            .then(res => {
              state.purchaseRefund = res.data.data
            })
      },
      async save(status) {
        const success = await validate()
        if (success) {
          state.purchaseRefund.status = status
          store.dispatch('purchaserefund/save', state.purchaseRefund)
              .then(res => {
                if (res.data.code === 0) {
                  toast.success('数据已保存!')
                  emit('closeModal')
                  if (refundId.value){
                   emit('refresh')
                  }
                } else {
                  toast.error('保存失败!')
                }
              })
        }
      },
      onUploaded(id, attachment, result) {
        state.purchaseRefund[id] = result
      },
      checkQty(item) {
        const g=/^-?[1-9]+[0-9]*$/;
        if(!g.test(item.qty)){
          toast.error("数量填写错误！")
          item.qty = 0
        }
        const qty = state.purchaseRefund.qty
        state.purchaseRefund.subtotal = (qty * state.purchaseRefund.product_cost_tax)
      }
    }



    onMounted(() => {
      if (refundId.value) {
        methods.edit()
      }
    })

    return {
      ...toRefs(state),
      ...methods,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
